import * as React from 'react';
import { IReportDefinition, IReportOutput } from '@models/reports';
import { ISecurity } from '@models';
import { EChart, useLoading, useTranslation } from '@components';
import { search } from '@store/actions/search';

export interface IProps {
    report: IReportDefinition;
    output: IReportOutput;
    security: ISecurity;
    workId: number;
}

export function ReportOutputPieChartComponent(props: IProps) {
    const loading = useLoading(true);
    const { t } = useTranslation();
    const [options, setOptions] = React.useState<any>({});
    const [data, setData] = React.useState<any[]>([]);

    const initialize = loading.wrap(async () => {
        const r = await search(props.workId, props.output.searchName, {});
        const rows = r.value;
        setData(rows);

        const data = rows.map((r: any) => {
            return {
                value: r.count,
                name: t(r.name)
            };
        });


        setOptions({
            aria: {
                decal: {
                    show: true
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c}'
            },
            series: [
                {
                    data: data,
                    type: 'pie'
                }
            ]
        });
    });

    React.useEffect(() => {
        if (props.output.autoStart) {
            initialize();
        }
    }, [props.output]);

    return <EChart options={options}
    height={400} width={600}/>;
}