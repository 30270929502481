import * as React from 'react';

import * as echarts from 'echarts';

export interface IProps {
    height?: number;
    width?: number;
    options: any;
}

export default function EChart(props: IProps) {
    const canvasRef = React.useRef<any>();
    const chartInstance = React.useRef<echarts.EChartsType>();

    const initialize = () => {
        const domElement = canvasRef.current;
        const parent = domElement.parentElement;
        const h = parseInt(parent.getBoundingClientRect().height);
        domElement.height = props.height ?? h;
        domElement.style.width = `${props.width ?? parent.getBoundingClientRect().width}px`;
        domElement.style.height = `${props.height ?? h}px`;

        // setHeight(`${h}px`);
        const chart = echarts.init(domElement);
        
        chart.setOption(props.options);
        
        chartInstance.current = chart;
    };

    React.useEffect(() => {
        initialize();
        
        return () => {
            if (chartInstance.current) {
                chartInstance.current.clear();
            }
        };
    }, [props.options]);

    return <div ref={canvasRef}></div>;
}